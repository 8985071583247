import React from 'react';
import OktaAuth from '@okta/okta-auth-js';
import { withAuth } from '@okta/okta-react';
import { Redirect } from 'react-router';
import config from '../../app.config';
var randomBackground = "background-container-" + Math.round(Math.random() * 4) + " register primary";




export default withAuth(
  class RegistrationForm extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        error: '',
        preError: false,
        firstName: '',
        firstNameInvalid: false,
        lastName: '',
        lastNameInvalid: false,
        email: '',
        emailInvalid: false,
        emailMatch: '',
        emailMatchInvalid: false,
        password: '',
        passwordInvalid: false,
        passwordMatch: '',
        passwordMatchInvalid: false,
        // affiliation2: 'none',
        // selectmsa: 'universal',
        company: '',
        companyInvalid: false,
        jobtitle: '',
        jobTitleInvalid: false,
        phone_number: '',
        phoneNumberInvalid: false,
        privacy_agree: 'true',
        // how_are_you_affiliated: '',
        affiliation: [],
        affiliationInvalid: false,
        affiliationOther: '',
        affiliationOtherInvalid: false,
        target_group: 'a_o_u',
        partner: [],

        partnerInvalid: false,
        redirect: false,
        sessionToken: null
      };



      this.oktaAuth = new OktaAuth({ url: config.url });
      this.checkAuthentication = this.checkAuthentication.bind(this);
      this.checkAuthentication();

      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
      this.handleLastNameChange = this.handleLastNameChange.bind(this);
      this.handleEmailChange = this.handleEmailChange.bind(this);
      this.handleEmailMatchChange = this.handleEmailMatchChange.bind(this);
      this.handlePasswordChange = this.handlePasswordChange.bind(this);
      this.handlePasswordMatchChange = this.handlePasswordMatchChange.bind(this);
      //   this.handleAffiliation2Change = this.handleAffiliation2Change.bind(this);
      //   this.handleSelectMSAChange = this.handleSelectMSAChange.bind(this);
      this.handleJobTitleChange = this.handleJobTitleChange.bind(this);
      this.handleCompanyChange = this.handleCompanyChange.bind(this);
      this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
      this.handleHowAreYouAffiliatedChange = this.handleHowAreYouAffiliatedChange.bind(this);
      this.handleAffiliationOtherChange = this.handleAffiliationOtherChange.bind(this);
      this.handlePartnerChange = this.handlePartnerChange.bind(this);
      this.validateFields = this.validateFields.bind(this);
    }

    async checkAuthentication() {
      const sessionToken = await this.props.auth.getIdToken();
      if (sessionToken) {
        this.setState({ sessionToken });
      }
    }

    componentDidUpdate() {
      this.checkAuthentication();
    }

    handleFirstNameChange(e) {
      this.setState({ firstName: e.target.value });
      this.setState({ firstNameInvalid: false });
    }
    handleLastNameChange(e) {
      this.setState({ lastName: e.target.value });
      this.setState({ lastNameInvalid: false });
    }
    handleEmailChange(e) {
      this.setState({ email: e.target.value });
      this.setState({ emailInvalid: false });
    }
    handleEmailMatchChange(e) {
      this.setState({ emailMatch: e.target.value });
      this.setState({ emailMatchInvalid: false });
    }
    handlePasswordChange(e) {
      this.setState({ password: e.target.value });
      this.setState({ passwordInvalid: false });
    }
    handlePasswordMatchChange(e) {
      this.setState({ passwordMatch: e.target.value });
      this.setState({ passwordMatchInvalid: false });
    }
    handleSelectMSAChange(e) {
      this.setState({ selectmsa: e.target.value });

    }
    handleJobTitleChange(e) {
      this.setState({ jobtitle: e.target.value });
      this.setState({ jobTitleInvalid: false });
    }
    handleCompanyChange(e) {
      this.setState({ company: e.target.value });
      this.setState({ companyInvalid: false });
    }
    handleAffiliation2Change(e) {
      this.setState({ affiliation2: e.target.value });
    }
    handleAffiliationOtherChange(e) {
      this.setState({ affiliationOther: e.target.value });
      this.setState({ affiliationOtherInvalid: false });
    }
    handleHowAreYouAffiliatedChange(e) {
      //   this.setState({ how_are_you_affiliated: e.target.value });
      this.setState({ affiliation: [e.target.value] });
      this.setState({ affiliationInvalid: false });
    }
    handlePartnerChange(e) {
      let selected_partners = [];
      let partner_select = document.getElementById('partner2')
      for (let i = 0; i < partner_select.selectedOptions.length; i++) {
        selected_partners.push(partner_select.selectedOptions[i].value)
      }
      console.log(selected_partners)
      //   this.setState({ partner2: e.target.val() });
      this.setState({ partner: selected_partners });
      //   this.setState({ partner: e.target.value });
      this.setState({ partnerInvalid: false });
    }



    handlePhoneNumberChange(e) {
      this.setState({ phone_number: e.target.value });
      this.setState({ phoneNumberInvalid: false });
    }




    validateFields = function (e) {

      e.preventDefault();
      this.setState({ preError: false });
      this.setState({ error: "" });


      //password match
      if (this.state.passwordMatch != this.state.password) {
        this.setState({ error: "Your passwords do not match! " });
        // console.log("Flagging passwordMatch");
        this.setState({ passwordMatchInvalid: true });
      }

      //password
      if (this.state.password != '') {
        if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(this.state.password)) {
          // console.log("Valid");
          this.setState({ passwordInvalid: false });
        } else {
          // console.log("Invalid");
          this.setState(prevState => {
            return {
              error: prevState.error + "Make sure your password meets minimum requirements! "
            }
          });
          this.setState({ passwordInvalid: true });
        }
      } else {
        // console.log("Flagging password");
        this.setState(prevState => {
          return {
            error: prevState.error + "Make sure your password meets minimum requirements! "
          }
        });
        //this.setState({ preError: true });
        this.setState({ passwordInvalid: true });
      }

      //email
      if (this.state.email == '') {
        // console.log("Flagging email");
        this.setState(prevState => {
          return {
            error: prevState.error + "Email address does not match! "
          }
        });
        this.setState({ emailInvalid: true });
      }

      //email match
      if (this.state.emailMatch != this.state.email) {
        // console.log("Flagging emailMatch");
        this.setState(prevState => {
          return {
            error: prevState.error + "Email address does not match! "
          }
        });
        this.setState({ emailMatchInvalid: true });
      }



      //first name	 
      if (this.state.firstName == '') {
        // console.log("Flagging first name");
        this.setState(prevState => {
          return {
            error: prevState.error + "Please enter your first name. "
          }
        });
        this.setState({ firstNameInvalid: true });

      }


      //last name 
      if (this.state.lastName == '') {
        // console.log("Flagging last name");
        this.setState(prevState => {
          return {
            error: prevState.error + "Please enter your last name. "
          }
        });
        this.setState({ lastNameInvalid: true });
      }

      //job	 
      if (this.state.jobtitle == '') {
        // console.log("Flagging job");
        this.setState(prevState => {
          return {
            error: prevState.error + "Please enter your job title. "
          }
        });
        this.setState({ jobTitleInvalid: true });
      }

      //company	 
      if (this.state.company == '') {
        // console.log("Flagging company");
        this.setState(prevState => {
          return {
            error: prevState.error + "Please enter your company name. "
          }
        });
        this.setState({ companyInvalid: true });
      }

      //phone	 
      if (this.state.phone_number == '') {
        // console.log("Flagging phone");
        this.setState(prevState => {
          return {
            error: prevState.error + "Please enter your phone number. "
          }
        });
        this.setState({ phoneNumberInvalid: true });
      }

      //how are you affiliated 	 
      // if(this.state.how_are_you_affiliated==''){
      if (this.state.affiliation.length === 0) {
        // console.log("Flagging Affiliation");
        this.setState(prevState => {
          return {
            error: prevState.error + "Please select your affiliation. "
          }
        });
        this.setState({ affiliationInvalid: true });
      }

      //affiliation other 
      if (this.state.affiliation[0] == 'Other' && this.state.affiliationOther == '') {
        // console.log("Flagging last name");
        this.setState(prevState => {
          return {
            error: prevState.error + "Please enter alternate affiliation. "
          }
        });
        this.setState({ affiliationOtherInvalid: true });
      }

      //partner	 
      // if(this.state.partner2==[]){
      if (this.state.partner == []) {
        console.log("Flagging Partner");
        this.setState(prevState => {
          return {
            error: prevState.error + "Please select your partner or partners. "
          }
        });
        this.setState({ partnerInvalid: true });
      }

      // console.log("phoneNumberInvalid:"+this.state.phoneNumberInvalid);
      // console.log("companyInvalid:"+this.state.companyInvalid);
      // console.log("jobTitleInvalid:"+this.state.jobTitleInvalid);
      // console.log("lastNameInvalid:"+this.state.lastNameInvalid);
      // console.log("firstNameInvalid:"+this.state.firstNameInvalid);
      // console.log("emailInvalid:"+this.state.emailInvalid);
      // console.log("emailMatchInvalid:"+this.state.emailMatchInvalid);
      // console.log("passwordInvalid:"+this.state.passwordInvalid);
      // console.log("passwordMatchInvalid:"+this.state.passwordMatchInvalid);
      // console.log("affiliationInvalid:"+this.state.affiliationInvalid);
    }






    async handleSubmit(e) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      await this.validateFields(e);
      if (this.state.phoneNumberInvalid === false && this.state.companyInvalid === false && this.state.jobTitleInvalid === false && this.state.lastNameInvalid === false && this.state.firstNameInvalid === false && this.state.emailInvalid === false && this.state.emailMatchInvalid === false && this.state.passwordInvalid === false && this.state.passwordMatchInvalid === false && this.state.affiliationInvalid === false && this.state.affiliationOtherInvalid === false && this.state.partnerInvalid === false) {

        fetch('/api/users', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.state)
        })
          .then(res => {
            if (!res.ok) {
              const message = res.json();
              message.then(res => {
                console.log("Response no good!", res);
                //   console.log(res.errorCauses[0].errorSummary, res.errorCode);
                //   res.errorCode == "E0000001" ?
                //   this.setState({ error: "An account with this email address is already in use. Go back to the login page to login." }) :
                //   this.setState({ error: "There was a problem creating an account... \n" + res.errorCauses[0].errorSummary });
                this.setState({ error: "There was a problem creating an account... \n" + res.errorCauses[0].errorSummary });
              })
            }
            this.oktaAuth
              .signIn({
                username: this.state.email,
                password: this.state.password
              })
              .then(res => {
                console.log("OktaAuth res:  ", res)
                this.setState({
                  sessionToken: res.sessionToken
                })
              }
              ).catch(err => console.log("okta auth error: ", err));;
          })
          .catch(err => console.log(err));
      }
    }

    render() {
      if (this.state.sessionToken) {
        this.props.auth.redirect({ sessionToken: this.state.sessionToken });
        return null;
      }

      const errorMessage = this.state.error ? (
        //    <div><span className="error-message">{this.state.error}</span> <p className="error-subtext">Please check that all of the required fields are filled out.</p></div>
        <div><span className="error-message">{this.state.error}</span></div>
      ) : null;



      return (

        <div className={randomBackground}>

          <div className="form-container">
            {errorMessage}
            <form onSubmit={this.handleSubmit}>
              <div className="form-element logo-element">
                <p className="logo">&nbsp;</p>

                <p>Create Account</p>
              </div>

              <div className={this.state.emailInvalid ? 'form-element email invalid' : 'form-element email'}>

                <label>Email:</label>
                <input
                  type="email"
                  id="email"
                  placeholder="Email*"
                  value={this.state.email}
                  onChange={this.handleEmailChange}
                />
              </div>

              <div className={this.state.emailMatchInvalid ? 'form-element emailMatch invalid' : 'form-element emailMatch'}>

                <label>Re-enter Email:</label>
                <input
                  type="email"
                  id="emailMatch"
                  placeholder="Confirm Email*"
                  value={this.state.emailMatch}
                  onChange={this.handleEmailMatchChange}

                />
              </div>

              <div className={this.state.passwordInvalid ? 'form-element password invalid' : 'form-element password'}>
                <label>Password:</label>
                <input
                  type="password"
                  id="password"
                  placeholder="Password*"
                  value={this.state.password}
                  onChange={this.handlePasswordChange}
                />
              </div>

              <div className={this.state.passwordMatchInvalid ? 'form-element password invalid' : 'form-element password'}>
                <label>Confirm Password:</label>
                <input
                  type="password"
                  id="passwordMatch"
                  placeholder="Confirm Password*"
                  value={this.state.passwordMatch}
                  onChange={this.handlePasswordMatchChange}

                />
              </div>

              {/* <span className="pass-text">Use 8 or more characters with a mix of letters, numbers & symbols including:</span>
	      <ul className="pass-ul">
	      <li>A lowercase letter</li>
	      <li>A capital (uppercase) letter</li>
	      <li>A number</li>
	      </ul> */}

              <span className="pass-text">Password requirements</span>
              <ul className="pass-ul">
                <li>At least 12 characters</li>
                <li>A lowercase letter</li>
                <li>An uppercase letter</li>
                <li>A symbol (@, $, !, %, *, #, ?, &)</li>
                <li>No parts of your username</li>
                <li>Does not include your first name</li>
                <li>Does not include your last name</li>
                <li>Password can't be the same as your last 24 passwords</li>
                <li>At least 2 hours must have elapsed since you last changed your password</li>
              </ul>

              <div className={this.state.firstNameInvalid ? 'form-element firstname invalid' : 'form-element firstname'}>
                <label>First Name:</label>
                <input
                  type="text"
                  id="firstName"
                  placeholder="First Name*"
                  value={this.state.firstName}
                  onChange={this.handleFirstNameChange}
                />
              </div>
              <div className={this.state.lastNameInvalid ? 'form-element lastname invalid' : 'form-element lastname'}>
                <label>Last Name:</label>
                <input
                  type="text"
                  id="lastName"
                  placeholder="Last Name*"
                  value={this.state.lastName}
                  onChange={this.handleLastNameChange}
                />
              </div>

              {/* <div className="form-element msa">
                <label>SelectMSA:</label>
                <input
                  type="text"
                  id="selectmsa"
                  value={this.state.selectmsa}
                  onChange={this.handleSelectMSAChange}
                />
              </div> */}

              <div className={this.state.companyInvalid ? 'form-element company invalid' : 'form-element company'}>
                <label>Company:</label>
                <input
                  type="text"
                  id="company"
                  placeholder="Company*"
                  value={this.state.company}
                  onChange={this.handleCompanyChange}
                />
              </div>
              <div className={this.state.jobTitleInvalid ? 'form-element jobtitle invalid' : 'form-element jobtitle'}>
                <label>JobTitle:</label>
                <input
                  type="text"
                  id="jobtitle"
                  placeholder="Job Title*"
                  value={this.state.jobtitle}
                  onChange={this.handleJobTitleChange}
                />
              </div>
              <div className={this.state.phoneNumberInvalid ? 'form-element phonenumber invalid' : 'form-element phonenumber'}>
                <label>Phone Number:</label>
                <input
                  type="tel"
                  id="phone_number"
                  placeholder="Phone Number*"
                  value={this.state.phone_number}
                  onChange={this.handlePhoneNumberChange}
                />
              </div>

              <div className={this.state.affiliationInvalid ? 'form-element affiliation invalid' : 'form-element affiliation'}>
                <label>All of Us Affiliation:</label>
                <span>Select your affiliation*</span>

                <select
                  id="how_are_you_affiliated"
                  //   value={this.state.how_are_you_affiliated}
                  value={this.state.affiliation}
                  onChange={this.handleHowAreYouAffiliatedChange}>

                  <option value="">{"<Choose One>"}</option>
                  <option value="ADWG Asset Development Working Group">ADWG Asset Development Working Group</option>
                  <option value="AoUAZ Arizona">AoUAZ Arizona</option>
                  <option value="AoUCA California">AoUCA California</option>
                  <option value="AoUH Heartland">AoUH Heartland</option>
                  <option value="AoUNE New England">AoUNE New England</option>
                  <option value="AoUPA Pennsylvania">AoUPA Pennsylvania</option>
                  <option value="AoUPR Puerto Rico">AoUPR Puerto Rico</option>
                  <option value="AoUSCC Southern California">AoUSCC Southern California</option>
                  <option value="AoUSN Southern Network">AoUSN Southern Network</option>
                  <option value="AoUWI Wisconsin">AoUWI Wisconsin</option>
                  <option value="BioBank">BioBank</option>
                  <option value="Color">Color</option>
                  <option value="DLWG Digital Literacy Working Group">DLWG Digital Literacy Working Group</option>
                  <option value="DRC Data and Research Center">DRC Data and Research Center</option>
                  <option value="DXC Technologies">DXC Technologies</option>
                  <option value="FQHC Federally Qualified Health Centers">FQHC Federally Qualified Health Centers</option>
                  <option value="ILPM Illinois Precision Medicine Consortium">ILPM Illinois Precision Medicine Consortium</option>
                  <option value="MITRE">MITRE</option>
                  <option value="NFCEOP">NFCEOP</option>
                  <option value="NIH National Institutes of Health">NIH National Institutes of Health</option>
                  <option value="NYC New York Coalition">NYC New York Coalition</option>
                  <option value="Palladian">Palladian</option>
                  <option value="PPSC Participant and Partner Services Center">PPSC Participant and Partner Services Center</option>
                  <option value="Pyxis">Pyxis</option>
                  <option value="RLWG Retention Language Working Group">RLWG Retention Language Working Group</option>
                  <option value="RTI Research Triangle Institute">RTI Research Triangle Institute</option>
                  <option value="SAGE Bionetworks">SAGE Bionetworks</option>
                  <option value="SEEC SouthEast Enrollment Center">SEEC SouthEast Enrollment Center</option>
                  <option value="TACH Trans-American Consortium of Health">TACH Trans-American Consortium of Health</option>
                  <option value="TPC Scripps">TPC Scripps</option>
                  <option value="UCLA University of California, Los Angeles">UCLA University of California, Los Angeles</option>
                  <option value="UOU University of Utah">UOU University of Utah</option>
                  <option value="VA US Department of Veterans Affairs">VA US Department of Veterans Affairs</option>
                  <option value="VUMC Vanderbilt University Medical Center">VUMC Vanderbilt University Medical Center</option>
                  <option value="Wondros">Wondros</option>
                  <option value="Xavier University of Louisiana (NFCEOP_XULA)">Xavier University of Louisiana (NFCEOP_XULA)</option>
                  <option value="Other">Other</option>

                </select>
              </div>

              <div className={(this.state.affiliationOtherInvalid ? 'form-element affiliationOther invalid' : 'form-element affiliationOther') + (this.state.affiliation[0] == 'Other' ? '' : ' hidden')}>
                <label>If other, please specify</label>
                <input
                  type="text"
                  id="affiliationOther"
                  placeholder="If other, please specify"
                  value={this.state.affiliationOther}
                  onChange={this.handleAffiliationOtherChange}
                />
              </div>

              <div class="partner2_copy">
                <label>Partner:</label>
                <span>Select your partner(s)*<br /></span><span>To select multiple partners:<br /></span><span>PC - Hold control (ctrl) button and choose additional partner(s)<br /></span>
                <span>Mac - Hold command button and choose additional partner(s)</span>
              </div>
              <div className={this.state.partnerInvalid ? 'form-element partner2 invalid' : 'form-element partner2'}>
                <select
                  id="partner2"
                  //   value={this.state.partner2}
                  onChange={this.handlePartnerChange}
                  //   size="10"
                  multiple
                >
                  {/* <option value="" disabled>{"<Choose One or More>"}</option> */}
                  <option value="1nHealth (TPC_1nHealth)">1nHealth (TPC_1nHealth)</option>
                  <option value="American Association on Health and Disability (NFCEOP_AAHD)">American Association on Health and Disability (NFCEOP_AAHD)</option>
                  <option value="American Indian Science and Engineering Society (NFCEOP_AISES)">American Indian Science and Engineering Society (NFCEOP_AISES)</option>
                  <option value="Asian Health Coalition (NFCEOP_AHC)">Asian Health Coalition (NFCEOP_AHC)</option>
                  <option value="Arab Community Center for Economic and Social Services (Pyxis_ACCESS)">Arab Community Center for Economic and Social Services (Pyxis_ACCESS)</option>
                  <option value="Baylor College of Medicine (NFCEOP_BCM)">Baylor College of Medicine (NFCEOP_BCM)</option>
                  <option value="Baylor Scott & White Health (BSWH)">Baylor Scott & White Health (BSWH)</option>
                  <option value="Blood Assurance (TPC_BA)">Blood Assurance (TPC_BA)</option>
                  <option value="BloodWorks Northwest (TPC_BWNW)">BloodWorks Northwest (TPC_BWNW)</option>
                  <option value="Blue Cross Blue Shield Association (TPC_BCBSA)">Blue Cross Blue Shield Association (TPC_BCBSA)</option>
                  <option value="Blue Cross Blue Shield of Louisiana (TPC_BCBSLA)">Blue Cross Blue Shield of Louisiana (TPC_BCBSLA)</option>
                  <option value="Boston Medical Center (BMC)">Boston Medical Center (BMC)</option>
                  <option value="Careevolution (TPC_CE)">Careevolution (TPC_CE)</option>
                  <option value="Cedars-Sinai Medical Center (CSMC)">Cedars-Sinai Medical Center (CSMC)</option>
                  <option value="Cherokee Health Systems (CHS)">Cherokee Health Systems (CHS)</option>
                  <option value="Color">Color</option>
                  <option value="Colorado (CO)">Colorado (CO)</option>
                  <option value="Community Health Center, Inc. (CHCI)">Community Health Center, Inc. (CHCI)</option>
                  <option value="Cook County Health (CCH)">Cook County Health (CCH)</option>
                  <option value="Cooper Green Mercy Health Services (CG)">Cooper Green Mercy Health Services (CG)</option>
                  <option value="Cooperative Health (COOP)">Cooperative Health (COOP)</option>
                  <option value="Corewell Health (CH)">Corewell Health (CH)</option>
                  <option value="COSSMA">COSSMA</option>
                  <option value="Delta Research and Education Foundation (NFCEOP_DREF)">Delta Research and Education Foundation (NFCEOP_DREF)</option>
                  <option value="Denver Health and Hospital Authority (TPC_Denver)">Denver Health and Hospital Authority (TPC_Denver)</option>
                  <option value="DLH Corp (TPC_DLHC)">DLH Corp (TPC_DLHC)</option>
                  <option value="DXC Technologies">DXC Technologies</option>
                  <option value="El Centro Regional Medical Center (ECRMC)">El Centro Regional Medical Center (ECRMC)</option>
                  <option value="Elevance Health (TPC_Anthem)">Elevance Health (TPC_Anthem)</option>
                  <option value="Endeavor Health (EH)">Endeavor Health (EH)</option>
                  <option value="Essentia Health (EH)">Essentia Health (EH)</option>
                  <option value="Faith Based Genetic Research Institute (RTI_FBGRI)">Faith Based Genetic Research Institute (RTI_FBGRI)</option>
                  <option value="Froedtert & MCW (MCW)">Froedtert & MCW (MCW)</option>
                  <option value="Governors State University (GSU)">Governors State University (GSU)</option>
                  <option value="Gundersen Health System (GHS)">Gundersen Health System (GHS)</option>
                  <option value="HealthPartners Institute (HP)">HealthPartners Institute (HP)</option>
                  <option value="Henry Ford Health (HFH)">Henry Ford Health (HFH)</option>
                  <option value="Kaiser Permanente Northwest (KPNW)">Kaiser Permanente Northwest (KPNW)</option>
                  <option value="Kalo Clinical Research (TPC_KCR)">Kalo Clinical Research (TPC_KCR)</option>
                  <option value="Leidos">Leidos</option>
                  <option value="LifeShare (TPC_LSBC)">LifeShare (TPC_LSBC)</option>
                  <option value="Loma Linda University Health (LLUH)">Loma Linda University Health (LLUH)</option>
                  <option value="Louisiana State University Health Sciences Center, New Orleans (LSU)">Louisiana State University Health Sciences Center, New Orleans (LSU)</option>
                  <option value="Mariposa Community Health Center (MCHC)">Mariposa Community Health Center (MCHC)</option>
                  <option value="Marshfield Clinic Research Institute (MFCRI)">Marshfield Clinic Research Institute (MFCRI)</option>
                  <option value="Marshfield Clinic Research Institute (NFCEOP_MFCRI)">Marshfield Clinic Research Institute (NFCEOP_MFCRI)</option>
                  <option value="Mary's Center for Maternal and Child Care (TPC_Mary's Center)">Mary's Center for Maternal and Child Care (TPC_Mary's Center)</option>
                  <option value="Massachusetts General Brigham (MGB)">Massachusetts General Brigham (MGB)</option>
                  <option value="Medical College of Wisconsin (MCW)">Medical College of Wisconsin (MCW)</option>
                  <option value="MemorialCare (MC)">MemorialCare (MC)</option>
                  <option value="Montage (TPC_MMG)">Montage (TPC_MMG)</option>
                  <option value="Morehouse School of Medicine (MHSM)">Morehouse School of Medicine (MHSM)</option>
                  <option value="Mount Sinai Health System (MSHS)">Mount Sinai Health System (MSHS)</option>
                  <option value="National Alliance for Hispanic Health (NFCEOP_NAHH)">National Alliance for Hispanic Health (NFCEOP_NAHH)</option>
                  <option value="National Institutes of Health (NIH)">National Institutes of Health (NIH)</option>
                  <option value="National Network of Libraries of Medicine (NFCEOP_NNLM)">National Network of Libraries of Medicine (NFCEOP_NNLM)</option>
                  <option value="National Opinion Research Center (NORC)">National Opinion Research Center (NORC)</option>
                  <option value="National Organization for Rare Disorders (PYXIS_NORD)">National Organization for Rare Disorders (PYXIS_NORD)</option>
                  <option value="New York Blood Centers (TPC_NYBC)">New York Blood Centers (TPC_NYBC)</option>
                  <option value="New York University (NFCEOP_NYU)">New York University (NFCEOP_NYU)</option>
                  <option value="NewYork-Presbyterian (NYP)">NewYork-Presbyterian (NYP)</option>
                  <option value="North Carolina Central University (NCCU)">North Carolina Central University (NCCU)</option>
                  <option value="Northwell Health (NFCEOP_NH)">Northwell Health (NFCEOP_NH)</option>
                  <option value="Northwestern Medicine Feinberg School of Medicine (NWFSM)">Northwestern Medicine Feinberg School of Medicine (NWFSM)</option>
                  <option value="NYC Health + Hospitals/Harlem (NHHH)">NYC Health + Hospitals/Harlem (NHHH)</option>
                  <option value="Palladian">Palladian</option>
                  <option value="Puerto Rico Consortium for Clinical Investigation (PRCCI)">Puerto Rico Consortium for Clinical Investigation (PRCCI)</option>
                  <option value="QTC (TPC_QTC)">QTC (TPC_QTC)</option>
                  <option value="Quest Diagnostics Inc. (TPC_QUEST)">Quest Diagnostics Inc. (TPC_QUEST)</option>
                  <option value="Regence (TPC_Regence)">Regence (TPC_Regence)</option>
                  <option value="Reliant Medical Group (RMG)">Reliant Medical Group (RMG)</option>
                  <option value="Research Triangle Institute (RTI)">Research Triangle Institute (RTI)</option>
                  <option value="Rush University (RU)">Rush University (RU)</option>
                  <option value="SAGE Bionetworks">SAGE Bionetworks</option>
                  <option value="Saint Louis University (SLU)">Saint Louis University (SLU)</option>
                  <option value="San Diego Blood Bank (TPC_SDBB-NBC)">San Diego Blood Bank (TPC_SDBB-NBC)</option>
                  <option value="San Ysidro Health (SYH)">San Ysidro Health (SYH)</option>
                  <option value="Scripps (TPC)">Scripps (TPC)</option>
                  <option value="South Texas Blood & Tissue (TPC_STBT)">South Texas Blood & Tissue (TPC_STBT)</option>
                  <option value="SunCoast Blood Centers (TPC_SCBC)">SunCoast Blood Centers (TPC_SCBC)</option>
                  <option value="Sun River Health (SRH)">Sun River Health (SRH)</option>
                  <option value="The University of Kansas Health System (KUHS)">The University of Kansas Health System (KUHS)</option>
                  <option value="Tulane University (TUL)">Tulane University (TUL)</option>
                  <option value="UAB School of Medicine, Huntsville Regional Medical Campus (UAB-HUNT)">UAB School of Medicine, Huntsville Regional Medical Campus (UAB-HUNT)</option>
                  <option value="UAB School of Medicine, Montgomery Internal Medicine (UAB-MONT)">UAB School of Medicine, Montgomery Internal Medicine (UAB-MONT)</option>
                  <option value="UAB School of Medicine, Selma Family Medicine Program (UAB-SELM)">UAB School of Medicine, Selma Family Medicine Program (UAB-SELM)</option>
                  <option value="UI Health (UI)">UI Health (UI)</option>
                  <option value="UMass Chan Medical School (UMCMS)">UMass Chan Medical School (UMCMS)</option>
                  <option value="University of Alabama, Birmingham Medical Center (UAB-MED)">University of Alabama, Birmingham Medical Center (UAB-MED)</option>
                  <option value="University of Alabama, University Medical Center (UA-UMC)">University of Alabama, University Medical Center (UA-UMC)</option>
                  <option value="University of Arizona (NFCEOP_UA)">University of Arizona (NFCEOP_UA)</option>
                  <option value="University of Arizona (UA)">University of Arizona (UA)</option>
                  <option value="University of Arizona-Banner Health (AZBH)">University of Arizona-Banner Health (AZBH)</option>
                  <option value="University of California, Davis (UCD)">University of California, Davis (UCD)</option>
                  <option value="University of California, Irvine (UCI)">University of California, Irvine (UCI)</option>
                  <option value="University of California, Los Angeles (UCLA)">University of California, Los Angeles (UCLA)</option>
                  <option value="University of California, San Diego (UCSD)">University of California, San Diego (UCSD)</option>
                  <option value="University of California, San Francisco (UCSF)">University of California, San Francisco (UCSF)</option>
                  <option value="University of Chicago (UC)">University of Chicago (UC)</option>
                  <option value="University of Florida (NFCEOP_UF)">University of Florida (NFCEOP_UF)</option>
                  <option value="University of Illinois at Chicago (UIC)">University of Illinois at Chicago (UIC)</option>
                  <option value="University of Iowa (UI)">University of Iowa (UI)</option>
                  <option value="University of Kansas Medical Center (KUMC)">University of Kansas Medical Center (KUMC)</option>
                  <option value="University of Miami, Miller School of Medicine (MMSM)">University of Miami, Miller School of Medicine (MMSM)</option>
                  <option value="University of Mississippi Medical Center (UMMC)">University of Mississippi Medical Center (UMMC)</option>
                  <option value="University of Missouri (MU)">University of Missouri (MU)</option>
                  <option value="University of Nebraska Medical Center (UNMC)">University of Nebraska Medical Center (UNMC)</option>
                  <option value="University of North Carolina at Chapel Hill (UNC-CH)">University of North Carolina at Chapel Hill (UNC-CH)</option>
                  <option value="University of North Carolina at Chapel Hill, Nutrition Research Institute (UNC-NRI)">University of North Carolina at Chapel Hill, Nutrition Research Institute (UNC-NRI)</option>
                  <option value="University of Pittsburgh (PITT)">University of Pittsburgh (PITT)</option>
                  <option value="University of Puerto Rico Comprehensive Cancer Center (UPRCCC)">University of Puerto Rico Comprehensive Cancer Center (UPRCCC)</option>
                  <option value="University of Puerto Rico Comprehensive Cancer Center (NFCEOP_UPRCCC)">University of Puerto Rico Comprehensive Cancer Center (NFCEOP_UPRCCC)</option>
                  <option value="University of South Alabama Health System (USA)">University of South Alabama Health System (USA)</option>
                  <option value="University of Southern California (USC)">University of Southern California (USC)</option>
                  <option value="University of Utah Genetic Science Learning Center (UOU_GSLC)">University of Utah Genetic Science Learning Center (UOU_GSLC)</option>
                  <option value="University of Wisconsin, Madison (UWM)">University of Wisconsin, Madison (UWM)</option>
                  <option value="U.S. Department of Veterans Affairs (VA)">U.S. Department of Veterans Affairs (VA)</option>
                  <option value="Vanderbilt University Medical Center (VUMC)">Vanderbilt University Medical Center (VUMC)</option>
                  <option value="Vibrent (PTSC)">Vibrent (PTSC)</option>
                  <option value="Waianae Coast Comprehensive Health Center (WCCHC)">Waianae Coast Comprehensive Health Center (WCCHC)</option>
                  <option value="Wake Forest Baptist Health Centers (WFBHC)">Wake Forest Baptist Health Centers (WFBHC)</option>
                  <option value="Weill Cornell Medicine (WCM)">Weill Cornell Medicine (WCM)</option>
                  <option value="Wondros">Wondros</option>
                  <option value="Xavier University of Louisiana (NFCEOP_XULA)">Xavier University of Louisiana (NFCEOP_XULA)</option>
                  <option value="Yale University (YALE)">Yale University (YALE)</option>
                  <option value="Other">Other</option>
                </select>
              </div>



              <div className="form-element submit-element">
                <span>* indicates a required field</span>

                <input type="submit" id="submit" value="Register" />

                <span className="login">Back to <a
                  href="/profilelanding"
                >
                  Login
                </a></span><br /><br /><a href="mailto:support@allofuscomms.org" className="supportEmail">support@allofuscomms.org</a>
              </div>
            </form>
          </div>
        </div>
      );
    }
  }
);
